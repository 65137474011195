import React, { ReactElement, useState } from "react";
import { Card, Grid, H3, H4, Modal, Button, Row } from "@maggioli-design-system/react";
import Question from "../Question/Question";
import { IGivenAns } from "../../interfaces/IGivenAnswer";
import { IQuestion } from "../../interfaces/IQuestion";

interface Props {
    showQuest: boolean;
    setShowQuest: Function;
    currentNumQuest: number;
    answers: IGivenAns[];
    showRight: boolean;
    endSimulation: Function;
    skipQuestions: boolean;
    goToQuestion: Function;
    questions: {
        num: number;
        data: IQuestion;
    }[];
    showEndButton?: boolean;
}

export default function QuestionRecap({
    showQuest,
    setShowQuest,
    skipQuestions,
    currentNumQuest,
    answers,
    questions,
    goToQuestion,
    showRight,
    endSimulation,
    showEndButton = true,
}: Props): ReactElement {
    const currentQuestion = questions[currentNumQuest];

    const skippedAnswers: IGivenAns[] = answers.filter((a) => a.answerID === null && a.questionID);
    const completedAnswers: IGivenAns[] = answers.filter((a) => a.answerID !== null && a.questionID);

    const skippedQuestions = questions
        .filter((q) => skippedAnswers.map((s) => s.questionID).includes(q.data.id))
        .filter((q) => q.data.id !== currentQuestion.data.id);
    const completedQuestions = questions
        .filter((q) => completedAnswers.map((s) => s.questionID).includes(q.data.id))
        .filter((q) => q.data.id !== currentQuestion.data.id);

    const currentQuestionStatus = completedAnswers.map((a) => a.questionID).includes(currentQuestion.data.id) ? "completed" : "default";

    const [finish, setFinish] = useState<boolean>(false);

    return (
        <Modal
            visible={showQuest}
            footer={false}
            onCancel={() => {
                setFinish(false);
                setShowQuest(false);
            }}
            position="left"
            maxHeight
            contentOnly
        >
            {!finish && (
                <Grid className="h-full p-3 pl-8" rows="fit-vertically" gutter="none">
                    <H4 className="mb-4 pt-5">Domanda corrente</H4>
                    <div onClick={() => setShowQuest(false)}>
                        <Question letter={currentQuestion.num.toString()} status={currentQuestionStatus}>
                            {currentQuestion.data.title}
                        </Question>
                    </div>

                    {skippedQuestions.length > 0 && <H4 className="mb-4 pt-5">Domande senza risposta</H4>}

                    {skippedQuestions.map((e, i) => (
                        <div key={i} className="mb-2 cursor-pointer" onClick={() => goToQuestion(e.num - 1)}>
                            <Question letter={e.num.toString()}>{e.data.title}</Question>
                        </div>
                    ))}

                    {completedQuestions.length > 0 && <H4 className="mb-4 pt-5">Domande completate</H4>}

                    {completedAnswers
                        .filter((c) => c.questionID !== currentQuestion.data.id)
                        .map((e, i) => {
                            const status = showRight && e.right ? "correct" : showRight ? "wrong" : "completed";
                            const question = questions.filter((c) => c.data.id === e.questionID).pop();
                            if (!question) {
                                return <div></div>;
                            }
                            return (
                                <div key={i} className="mb-2 cursor-pointer" onClick={() => goToQuestion(question.num - 1)}>
                                    <Question letter={question.num.toString()} status={status}>
                                        {question.data.title}
                                    </Question>
                                </div>
                            );
                        })}

                    {showEndButton && (
                        <Row className="mt-5" lastChild="to-right">
                            <div>
                                <Button variant="success" onClick={() => setFinish(true)} icon="crud-save">
                                    Termina
                                </Button>
                            </div>
                        </Row>
                    )}

                    <br />
                </Grid>
            )}

            {finish && (
                <Grid className="h-full p-3 pl-8" rows="fit-vertically">
                    <Card className="bg-adjust-tone-19 quiz-card mt-4 overflow-hidden shadow hover:shadow-lg">
                        <H3 className="-mb-5">Attenzione</H3>
                        {skipQuestions ? (
                            <Grid gutter="xsmall">
                                Vuoi davvero terminare la simulazione?
                                <Grid columns="2" gutter="small">
                                    <Button
                                        variant="primary-outline"
                                        onClick={() => {
                                            setShowQuest(false);
                                            setFinish(false);
                                        }}
                                    >
                                        Annulla
                                    </Button>
                                    <Button
                                        variant="success"
                                        onClick={() => {
                                            setShowQuest(false);
                                            endSimulation();
                                        }}
                                        icon="crud-save"
                                    >
                                        Termina
                                    </Button>
                                </Grid>
                            </Grid>
                        ) : (
                            <Grid gutter="xsmall">
                                Completare tutte le domande prima di terminare la simulazione. <br />
                                ATTENZIONE: Allo scadere del tempo le domande senza risposta verrano considerate non corrette.
                                <Grid columns="2" gutter="small">
                                    <div></div>
                                    <Button
                                        variant="primary-outline"
                                        onClick={() => {
                                            setShowQuest(false);
                                            setFinish(false);
                                        }}
                                    >
                                        Annulla
                                    </Button>
                                </Grid>
                            </Grid>
                        )}
                    </Card>

                    <br />
                </Grid>
            )}
        </Modal>
    );
}
