import {
    Button,
    Grid,
    H5,
    Hr,
    Tag,
} from '@maggioli-design-system/react'
import { ReactElement, useEffect, useState } from 'react'
import AnswerDescription from '../../components/AnswerDescription/AnswerDescription';
import QuestionRecap from '../../components/QuestionRecap/QuestionRecap';
import QuizAnswers from '../../components/QuizAnswers/QuizAnswers';
import QuizHeader from '../../components/QuizHeader/QuizHeader';
import QuizTopBar from '../../components/QuizTopBar'
import { IGivenAns } from '../../interfaces/IGivenAnswer';
import { IQuestion } from '../../interfaces/IQuestion';
import { getQuestionSet } from '../../services/CustomerHistoryService';
import { getDifficulty, scrollTop } from '../../utils/Functions'

interface IOrderedQuestions {
    num: number,
    data: IQuestion,
}

interface Props {
    simulationHistory: any
}

const QuizRecap = ({ simulationHistory }: Props): JSX.Element => {

    const simulation = JSON.parse(simulationHistory.json_data);
    const givenAns: IGivenAns[] = JSON.parse(simulationHistory.json_data).answers;

    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [currentNumQuest, setCurrentNumQuest] = useState<number>(0);
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeydown);

        return () => {
            document.removeEventListener("keydown", handleKeydown);
          };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNumQuest])

    const handleKeydown = (e) => {
        switch(e.key) {
            case "a": 
                onClickPrev();
                break;
            case "ArrowLeft": 
                onClickPrev();
                break;
            case "d": 
                onClickNext();
                break;
            case "ArrowRight": 
                onClickNext();
                break;
            default: break;
        }
    }

    useEffect(() => {
        setCurrentNumQuest(0);
        loadQuestions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationHistory]);

    const loadQuestions = async () => {
        const res = await getQuestionSet(simulationHistory.id);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        if (!data.status) {
            return;
        }
        setQuestions(data.data);
    }

    const onClickNext = () => {
        if (currentNumQuest === questions.length - 1) {
            return;
        }
        scrollTop(); 
        setCurrentNumQuest(currentNumQuest + 1);
    }
    
    const goToQuestion = (numQuestion: number) => {
        scrollTop();
        setCurrentNumQuest(numQuestion);
        setShowQuestions(false);
    }

    const onClickPrev = () => {
        if (currentNumQuest === 0) {
            return;
        }
        scrollTop();
        setCurrentNumQuest(currentNumQuest - 1);
    }

    if (currentNumQuest < 0 || questions.length === 0) {
        return <div></div>;
    }

    const prevQuestButon: ReactElement = <Button icon="paginator-previous" variant="primary-outline"
                                                onClick={() => onClickPrev()}>Precedente</Button>

    const nextQuestButton = () => { 
        return <Button icon="paginator-next" iconPosition="right" onClick={() => onClickNext()}>
            Prossima domanda
        </Button>  
    }

    const seeQuestionsButton: ReactElement = 
        <Button variant="primary-outline" icon="todo-list" onClick={() => setShowQuestions(true)}>Riepilogo domande</Button>

    const actionButons = () => {          
        if (currentNumQuest === 0) {

            return <Grid columns='4' className="">
                <div></div>
                { seeQuestionsButton }
                { nextQuestButton() }
            </Grid>
        }

        if (currentNumQuest > 0 && currentNumQuest < questions.length - 1) {
            return <Grid columns='3'>
                { prevQuestButon }
                { seeQuestionsButton }
                { nextQuestButton() }
            </Grid>
        }

        if (currentNumQuest === questions.length - 1) {
            return <Grid columns='3'>
                { prevQuestButon }
                { seeQuestionsButton }
            </Grid>
        }
    }

    if (currentNumQuest < 0 || questions.length === 0) {
        return <div></div>;
    }

    const currentQuestion: IQuestion = questions[currentNumQuest];

    const questionsAndAnswers = () => {

        return <div>
            <QuizTopBar numQuestion={currentNumQuest + 1} diff={getDifficulty(questions)} numGivenAns={givenAns.length} simulation={simulation.simulation}
                    className="flex-wrap gap-4 desktop-max:gap-2" questionTime={givenAns[currentNumQuest].time} time={simulation.total_time}/>
            <Hr className="bg-adjust-tone-18 my-4"/>

            <QuizHeader timeLeftInSeconds={100} noStop={false} endModal={true} currentQuestion={currentQuestion}/> 
            
            <QuizAnswers givenAns={givenAns} selectAnswer={() => {}} currentQuestion={currentQuestion} showRight={true} recap={true}/> 
            <AnswerDescription question={currentQuestion}/>
        </div>
    }

    const isAnswered = givenAns.filter(g => g.questionID === currentQuestion.id && g.answerID !== null).length > 0;
    const customQuestions: IOrderedQuestions[] = questions.map((q, i) => { return { num: i + 1, data: q }}); 

    return <div className="bg-adjust-tone py-12 mobile:pt-4 view-limit desktop-max:p-4">
        { questionsAndAnswers() }

        <Grid>{ actionButons() }</Grid>

        { !isAnswered && <div className='text-center mt-4'><Tag icon="crud-cancel" chip><H5>Risposta non data</H5></Tag></div>}

        <QuestionRecap showQuest={showQuestions} setShowQuest={setShowQuestions} currentNumQuest={currentNumQuest} answers={givenAns}
                questions={customQuestions} showRight={true} endSimulation={() => {}}
                skipQuestions={true} showEndButton={false} goToQuestion={goToQuestion}/>         
    </div>
}

export default QuizRecap;
