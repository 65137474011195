import { BenchmarkBar, Button, Detail, Grid, Hr, H2, H4, Row, Modal } from "@maggioli-design-system/react";
import { useParams, Link } from "react-router-dom";
import SimulationBadge from "../../components/SimulationBadge";
import DescriptionTip from "../../components/DescriptionTip";
import Title from "../../components/Title";
import { calculateScore, fromMsToS, fromMsToTime, getCookie, roundOneDecimal, scorePerTheme, timeConvert } from "../../utils/Functions";
import { useEffect, useState } from "react";
import { getUserHistory } from "../../services/CustomerHistoryService";
import { format } from "timeago.js";
import { ILoader } from "../../interfaces/ILoader";
import { IHistory } from "../../interfaces/ICustomerHistory";
import QuizRecap from "../QuizRecap/QuizRecap";
import { getTestByID } from "../../services/TestService";
import { emailParams, IMAGE_EXPRESS_URL } from "../../utils/Macros";
import { sendDataNoPrivacy } from "../../services/PrivacyService";
import { ISubmit } from "../../interfaces/ISubmit";
import PlaceholderImage from "../../default_image.jpg";

const speed = (velocity: number) => {
    let status = "Velocissimo";
    if (velocity < 85) {
        status = "Molto veloce";
    }
    if (velocity < 80) {
        status = "Spedito";
    }
    if (velocity < 70) {
        status = "Nella media";
    }
    if (velocity < 50) {
        status = "Lento";
    }
    if (velocity < 40) {
        status = "Molto lento";
    }
    if (velocity < 20) {
        status = "Lentissimo";
    }
    return status;
};

interface Props {
    handleLoader: ILoader;
}

const QuizResults = ({ handleLoader }: Props): JSX.Element => {
    const { id } = useParams<{ id: string }>();

    const IS_LOGGED: boolean = getCookie("loggedUser") !== null;
    const [simulationHistory, setSimulationHistory] = useState<IHistory[]>([]);
    const [simulationIndex, setSimulationIndex] = useState<number>(-1);
    const [showSimulation, setShowSimulation] = useState<boolean>(false);
    const [submit, setSubmit] = useState<ISubmit>({ text: "Clicca qui", status: "" });

    useEffect(() => {
        getSimulationHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        showSelectedSimulation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationHistory]);

    useEffect(() => {
        if (simulationHistory.length === 0 || simulationIndex === -1) {
            return;
        }
        // getOtherUsersProgress(simulationHistory[simulationIndex]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [simulationIndex]);

    const showSelectedSimulation = () => {
        const simulationID: string = localStorage.getItem("simulationID");
        if (simulationID === null) {
            setSimulationIndex(simulationHistory.length - 1);
            return;
        }

        simulationHistory.forEach((e, i) => {
            if (e.id.toString() === simulationID) {
                setSimulationIndex(i);
                localStorage.removeItem("simulationID");
            }
        });
    };

    const getSimulationHistory = async () => {
        handleLoader.setLoaderVisible(true);
        const res = await getUserHistory(id, JSON.parse(getCookie("loggedUser")).id);
        if (!res.ok) {
            return;
        }
        const data = await res.json();
        if (data.length === 0) {
            return;
        }
        handleLoader.setLoaderVisible(false);
        setSimulationHistory(data);
    };

    if (simulationHistory.length === 0 || simulationIndex === -1) {
        return (
            <div className="bg-adjust-tone py-12 px-4 mobile:pt-4">
                <Grid className="grid-cols-3 tablet-max:grid-cols-1 gap-8">
                    <div></div>
                    <Grid className="gap-6 auto-rows-min">
                        <H2>Risultati simulazione</H2>
                        <Hr className="bg-adjust-tone-18 -my-5" />
                        Nessuna simulazione completata.
                        <Link to="/">
                            <Button variant="primary-outline" icon="action-back" horizontalPadding="none">
                                Torna alla home
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </div>
        );
    }

    const lastSimu = JSON.parse(simulationHistory[simulationIndex].json_data);
    const config = lastSimu.simulation.settings.data;

    const avgTime: number = lastSimu.total_time / lastSimu.answers.length;
    const timeStatus: number = 100 - Math.round((lastSimu.total_time / 1000 / (config.time * 60)) * 100);
    const rightAnswers: number = lastSimu.answers.filter((e) => e.answerID !== null && e.right).length;
    const wrongAnswers: number = lastSimu.answers.filter((e) => e.answerID !== null && e.right === false).length;
    const skippedAnswers: number = lastSimu.answers.filter((e) => e.answerID === null).length;
    const score: number = rightAnswers * config.right + skippedAnswers * config.empty + wrongAnswers * config.wrong;
    const totalPoints: number = scorePerTheme(config)
        ? config.themes.reduce((a, b) => a + b.number * b.right, 0)
        : config.right * config.total_questions;

    const imageAndLink = (test) => {
        const defaultLink: string = "https://www.maggiolieditore.it/concorsi-e-abilitazioni.html";
        const link: string = test.data.link === "" ? defaultLink : test.data.link;
        const image =
            test.data.metadata.data.url !== undefined && test.data.metadata.data.url !== ""
                ? `${IMAGE_EXPRESS_URL}url=${test.data.metadata.data.url}`
                : process.env.REACT_APP_EXPRESS_URL + PlaceholderImage;

        return `<a href=${link} target="_blank"><img width="600px" src="${image}"/></a>`;
    };

    const onClickSendEmail = async () => {
        if (!IS_LOGGED || submit.status !== "") {
            return;
        }
        const res = await getTestByID(lastSimu.simulation.test_id);
        if (!res.ok) {
            return;
        }
        const test = await res.json();

        const loggedUser = getCookie("loggedUser");

        const themesData = config.themes
            .filter((t) => t.number > 0)
            .map((t, i) => {
                const questionsID: string = lastSimu.simulation.questions.filter((q) => q.theme_id === t.id).map((q) => q.id);
                const correctAnswersInTheme: number = lastSimu.answers.filter((a) => a.right && questionsID.includes(a.questionID)).length;
                const score: number = Math.round((correctAnswersInTheme / t.number) * 100);
                return `<tr style="background-color: ${i % 2 === 0 ? "#eee" : "#fff"};">
                        <td>${t.title.split(" - ")[0]}</td>
                        <td>${correctAnswersInTheme} / ${t.number}</td>
                        <td>(${score} %)</td>
                    </tr>`;
            });

        const marketingArea = () => {
            const imagesPath: string = "https://mn.newslettermaggioli.it/nl/res/4036/newsletters/25192/img/";
            return `<div>
                <table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0" width="600">
                    <tbody>
                        <tr>
                            <td valign="top" style="text-align:center;">
                                <a href="https://t.me/maggiolieditoreconcorsipubblici" target="_blank"><img align="middle" border="0" height="176" src="${imagesPath}icon_canalisocial3_1.jpg" style="display:block;text-align:center;margin-left:auto;margin-right:auto;max-width:176px!important;height: auto !important" title="" width="100"></a>
                            </td>
                            <td valign="top">
                                <a href="https://www.facebook.com/ConcorsiAbilitazioni" target="_blank"><img align="middle" border="0" height="176" src="${imagesPath}icon_canalisocial_w100_h176.jpg" style="display:block;text-align:center;margin-left:auto;margin-right:auto;max-width:176px!important;height: auto !important" title="" width="100"></a>
                            </td>
                            <td valign="top">
                                <a href="https://www.leggioggi.it/iscrizione-newsletter" target="_blank"><img align="middle" border="0" height="176" src="${imagesPath}icon_canalisocial2_w100_h176.jpg" style="display:block;text-align:center;margin-left:auto;margin-right:auto;max-width:176px!important;height: auto !important" title="" width="100"></a>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <a class="gtimglink" href="https://www.facebook.com/ConcorsiAbilitazioni" target="_blank"><img align="middle" height="200" src="${imagesPath}footer_w600_h200.jpg" style="display:block;text-align:center;margin-left:auto;margin-right:auto;;max-width:560px" title="" width="600"></a>
            </div>`;
        };

        const payload = {
            to: JSON.parse(loggedUser).email, // email amministrazione
            from: "no-reply-siti-web@maggiolieditore.it", //email no replay sito
            fromName: "Simulatore Quiz Concorsi", //nome sito
            replyTo: "no-reply-siti-web@maggiolieditore.it", //email inserita dall'utente
            subject: "Riepilogo prova",
            message: `<h2>Riepilogo "${test.data.title}"</h2>
                        <b>Tempo</b><br/>
                        ${fromMsToTime(lastSimu.total_time, true)} / ${timeConvert(config.time)} <br/>
                        Tempo medio a domanda: ${fromMsToS(avgTime)} <br/> <br/>
                        <b>Domande</b><br/>
                        <table>
                            <tr style="background-color: #eee;">
                                <td>Corrette</td>
                                <td>${rightAnswers.toString()}</td>
                            </tr>
                            <tr>
                                <td>Sbagliate</td>
                                <td>${wrongAnswers.toString()}</td>
                            </tr>
                            <tr style="background-color: #eee;">
                                <td>Saltate</td>
                                <td>${skippedAnswers.toString()}</td>
                            </tr>
                        </table><br/>
                        <b>Materie</b><br/>
                        <table>
                            ${themesData.join("")}
                        </table><br/>
                        <h3><b>Completa la tua preparazione</b></h3>
                        ${imageAndLink(test)} <br/>
                        ${marketingArea()}</p>`,
            params: {
                ...emailParams,
            },
            logo: "simulatorequizconcorsi",
            template: "simulatorequiz",
        };
        handleLoader.setLoaderVisible(true);
        const res2 = await sendDataNoPrivacy(payload);
        handleLoader.setLoaderVisible(false);
        if (!res2.ok) {
            return;
        }
        const data2 = await res2.json();
        if (data2.status) {
            setSubmit({ text: "Email inviata!", status: "success" });
        }
    };

    return (
        <div className="bg-adjust-tone py-12 px-4 mobile:pt-4">
            <Modal visible={showSimulation} onCancel={() => setShowSimulation(false)} maxWidth footer={false}>
                <QuizRecap simulationHistory={simulationHistory[simulationIndex]} />
            </Modal>

            <div className="">
                <Grid className="grid-cols-3 tablet-max:grid-cols-1 gap-8">
                    <div></div>
                    <Grid className="gap-4 auto-rows-min">
                        <H2>Risultati simulazione</H2>

                        <b className="">
                            <label>
                                {lastSimu.simulation.title} ({format(simulationHistory[simulationIndex].created_at, "it")})
                            </label>
                        </b>
                        <Row lastChild="to-right">
                            <Button variant="primary-outline" onClick={() => setShowSimulation(true)}>
                                Vedi risposte
                            </Button>
                            <div>
                                <Row>
                                    <Button
                                        variant="primary-outline"
                                        disabled={simulationIndex === 0}
                                        icon="paginator-previous"
                                        onClick={() => setSimulationIndex(simulationIndex - 1)}
                                    />
                                    <Button
                                        variant="primary-outline"
                                        disabled={simulationIndex === simulationHistory.length - 1}
                                        onClick={() => setSimulationIndex(simulationIndex + 1)}
                                        icon="paginator-next"
                                    />
                                </Row>
                            </div>
                        </Row>

                        <SimulationBadge
                            pointsMade={scorePerTheme(config) ? calculateScore(lastSimu) : roundOneDecimal(score)}
                            pointsNeeded={config.min_score}
                            pointsTotal={totalPoints}
                        />

                        {/* { progresses === null &&
                    <div className="mx-auto my-2"><ThreeDots color="#0041B9"/></div>} */}

                        <H4>Riepilogo</H4>
                        <Hr className="bg-adjust-tone-18 m-0" />

                        <Grid className="gap-4">
                            <Title icon="timer-outline">Tempo</Title>
                            <Grid className="gap-0">
                                <DescriptionTip fullWidth={true} value={timeConvert(config.time)}>
                                    totale disponibile
                                </DescriptionTip>
                                <DescriptionTip fullWidth={true} value={fromMsToTime(lastSimu.total_time, true)}>
                                    impiegato
                                </DescriptionTip>
                                <DescriptionTip fullWidth={true} value={fromMsToS(avgTime)}>
                                    Media a domanda
                                </DescriptionTip>
                                <BenchmarkBar autoColor progress={timeStatus} progressText={speed(timeStatus)}>
                                    Velocità
                                </BenchmarkBar>
                            </Grid>
                        </Grid>

                        <Grid className="grid-cols-2 mobile:grid-cols-1 gap-10">
                            <Grid rows="fit-vertically" className="gap-4">
                                <Title icon="rule">Risposte</Title>
                                <Grid rows="fit-vertically" className="gap-0">
                                    <DescriptionTip fullWidth={true} value={rightAnswers.toString()}>
                                        Corrette
                                    </DescriptionTip>
                                    <DescriptionTip fullWidth={true} value={wrongAnswers.toString()}>
                                        Sbagliate
                                    </DescriptionTip>
                                    <DescriptionTip fullWidth={true} value={skippedAnswers.toString()}>
                                        Saltate
                                    </DescriptionTip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Row className="mt-3">
                            <Button onClick={() => onClickSendEmail()} variant={submit.status}>
                                {submit.text}
                            </Button>
                            <Detail>Se vuoi ricevere per email tutti dettagli sulla prova appena completata</Detail>
                        </Row>

                        {/* <Grid className="grid-cols-1 mobile:grid-cols-1 gap-10">
                        <Grid className="gap-4">
                            <Title icon="data-category">Risposte per materia</Title>
                            { config.themes.filter(t => t.number > 0).map(t => {
                                const questionsID: string = lastSimu.simulation.questions.filter(q => q.theme_id === t.id).map(q => q.id);
                                const correctAnswersInTheme: number = lastSimu.answers.filter(a => a.right && questionsID.includes(a.questionID)).length;
                                const score: number = correctAnswersInTheme / t.number * 100;
                                return <BenchmarkBar autoColor progress={score} progressText={`${correctAnswersInTheme} / ${t.number}`}>
                                    {t.title}
                                </BenchmarkBar>
                            })}
                        </Grid>
                    </Grid> */}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default QuizResults;
